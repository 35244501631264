import React from 'react'
import { Helmet } from 'react-helmet'

interface MetaTagsProps {
	title?: string
	description?: string
	keywords?: string
	tags?: {
		name: string
		content: string
	}[]
}

const MetaTags = ({
	title = 'New & Pre-Owned Luxury Watches | The 1916 Company',
	description = 'Shop, trade & sell with one of the largest collections of new & used luxury watches online, The 1916 Company.',
	keywords = '',
	tags,
}: MetaTagsProps) => {
	return (
		<Helmet>
			<title>{title}</title>
			{keywords && <meta name="keywords" content={keywords} />}
			{description && <meta name="description" content={description} />}
			{tags &&
				tags.map(({ name, content }) => (
					<meta key={name} name={name} content={content} />
				))}
		</Helmet>
	)
}

export default MetaTags
