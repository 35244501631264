import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocale } from '@core/locale'
import { usePathname, useQueryString } from '@next/navigation'

/* Components */
import MetaTags from '@components/base/MetaTags/MetaTags'

/* Local Types */
type metaType = {
	title?: string
	description?: string
	keywords?: string
	[key: string]: string | undefined
}
type ogType = {
	title?: string
	description?: string
	image?: string
}
type verificationsType = {
	[name: string]: string
}
export type pageMetasType = {
	pageTitle?: string
	pageDescription?: string
	pageKeywords?: string
	[key: string]: string | undefined
}

/* Default Meta */
const useMetas = (meta: metaType, pageMetas?: pageMetasType): metaType => {
	const defaultMeta: metaType = {}
	if (pageMetas) {
		['pageTitle', 'pageDescription', 'pageKeywords'].forEach((key) => {
			if (pageMetas[key]) {
				defaultMeta[key.replace('page', '').toLowerCase()] = pageMetas[key]
			}
		})
	}
	return Object.assign(defaultMeta, meta || {})
}

/* Fixed Site Constants */
const domain: string = 'https://www.the1916company.com'
const siteName: string = 'The 1916 Company'

/* Fixed Site Verifications */
const verifications: verificationsType = {
	'facebook-domain-verification': '2168ylbjs2bqxd2pdlajejabp1qfgb',
	'p:domain_verify': 'ad9f32857a967c1d492bcfc2df59c312',
	'wot-verification': 'dc0f62f82f0c7688c5cf',
	'google-site-verification': 'FOJ3t1Tw2feyl1vaWQnSbqtplwhFc-onBzDY4_6F824',
	'fb:app_id': '282647099973572',
}

/* Empty to skip twitter handle - no twitter for 1916 */
const twitterHandle: string = '' //'@watchbox_global';

const Page = ({
	children,
	meta,
	og,
	canonical,
	pageMetas,
}: {
	children: React.ReactNode
	meta?: metaType
	og?: ogType
	canonical?: string
	pageMetas?: pageMetasType
}): React.ReactElement => {
	const pathname = usePathname()
	const queryString = useQueryString()

	/* Locale */
	const locale: string = useLocale()

	/* Calculated Metas */
	meta = useMetas(meta || {}, pageMetas || {})

	/* OG */
	const ogFinal: ogType = Object.assign({
		title: og?.title?.toString() || meta?.title?.toString() || '',
		description:
			og?.description?.toString() || meta?.description?.toString() || '',
		image:
			og?.image?.toString() ||
			'https://the1916company.imgix.net/1916_og_logo.png',
	})

	/* Exclude Request Processor Cache */
	const queryStringParams = new URLSearchParams(queryString)
	queryStringParams.delete('cache')
	const safeQueryString = queryStringParams.toString()

	/* Full Canonical URL */
	const canonicalUrl: string = `${domain}${canonical || pathname}`

	/* Full URL */
	const url: string = `${canonicalUrl}${
		safeQueryString ? `?${safeQueryString}` : ''
	}`

	/* Remove trailing empty query from URLs */
	const safeUrl: string = url.replace(/\?{1,3}$/, '')
	const safeCanonicalUrl: string = canonicalUrl.replace(/\?{1,3}$/, '')

	return (
		<>
			<MetaTags
				title={meta.title}
				keywords={meta.keywords}
				description={meta.description}
			/>
			<Helmet>
				<link rel={`canonical`} href={safeCanonicalUrl} />

				{/* Cache Control */}
				<meta http-equiv="last-cache" content={new Date().toUTCString()} />

				{/* Open Graph */}
				<meta property="og:url" content={safeUrl} />
				<meta property="og:site_name" content={siteName} />
				<meta property="og:locale" content={locale.replace('-', '_')} />
				<meta property="og:type" content="website" />
				{ogFinal.title && <meta property="og:title" content={ogFinal.title} />}
				{ogFinal.description && (
					<meta property="og:description" content={ogFinal.description} />
				)}
				<meta property="og:image" content={ogFinal.image} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta property="og:image:alt" content={siteName} />

				{/* Twitter */}
				<meta name="twitter:card" content="summary" />
				{twitterHandle && <meta name="twitter:site" content={twitterHandle} />}
				{twitterHandle && (
					<meta name="twitter:creator" content={twitterHandle} />
				)}
				<meta
					name="twitter:title"
					content={ogFinal.title || meta.title || ''}
				/>
				<meta
					name="twitter:description"
					content={ogFinal.description || meta.description || ''}
				/>
				<meta name="twitter:image" content={ogFinal.image} />
				<meta name="twitter:image:alt" content={siteName} />

				{/* Apple */}
				<meta
					name="apple-itunes-app"
					content="app-id=738072910, app-argument=https://www.the1916company.com/,"
				/>

				{/* Verifications */}
				{Object.entries(verifications).map(([name, content]) => {
					return name == 'fb:app_id' ? (
						<meta key={name} property={name} content={content} />
					) : (
						<meta key={name} name={name} content={content} />
					)
				})}

				{/* Robots */}
				<meta name="robots" content="index, follow" />
				<meta name="robots" content="max-image-preview:large" />

				{/* Hreflang */}
				<link
					rel="alternate"
					href={safeUrl}
					hrefLang={locale.replace('_', '-')}
				/>
				<link
					rel="alternate"
					href={safeUrl}
					hrefLang={locale.substring(0, 2)}
				/>
				<link rel="alternate" href={safeUrl} hrefLang="x-default" />
			</Helmet>
			{children}
		</>
	)
}

export default Page
