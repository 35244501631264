import React, { useCallback, useEffect, useRef, useState } from 'react'

/* Components */
import BannerPicture, { BannerPictureProps } from '../common/BannerPicture'
import BannerSlider from '../common/BannerSlider/BannerSlider'
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { generateCSSClassString } from '@helpers/cssHelpers/index'
import { replaceEscapedNewlines } from '@lib/strapi/helpers'

/* Hooks */
import { useBannerSwipeable } from '../common/useBannerSwipeable'

/* Styles */
import HeroBannerStyles from './HeroBanner.module.scss'

/* Local Types */
interface HeroBannerSlide {
	id: number
	picture: BannerPictureProps
	mainItem?: HeroBannerSlideMainItem
	internalItem?: HeroBannerSlideInternalItem
	sliderTextColor?: string
	sliderSelectedColor?: string
}

interface HeroBannerSlideMainItem {
	title: string
	titleAlignment?: 'left' | 'center' | 'right'
	titleColor?: string
	ctaAlignment?: 'left' | 'center' | 'right'
	ctaText: string
	ctaTextColor?: string
	ctaLinkHref: string
	ctaBackgroundColor?: string
	ctaBorderColor?: string
	position: HeroBannerPosition
}

interface HeroBannerSlideInternalItem {
	title: string
	titleColor?: string
	actionCTAs: HeroBannerSlideLinkCTA[]
}

interface HeroBannerSlideLinkCTA {
	text: string
	href: string
	type: 'primary' | 'secondary'
}

interface HeroBannerPosition {
	left?: number
	right?: number
	bottom?: number
	top?: number
}

const HeroBanner = ({
	slides,
	interval = 6,
}: {
	slides: HeroBannerSlide[]
	interval?: number
}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const bannerRef = useRef(null)

	/* Local Callbacks */
	const nextSlide = useCallback(() => {
		setActiveIndex((currIndex) =>
			currIndex === slides.length - 1 ? 0 : currIndex + 1
		)
	}, [slides.length])
	const previousSlide = useCallback(() => {
		setActiveIndex((currIndex) =>
			currIndex === 0 ? slides.length - 1 : currIndex - 1
		)
	}, [slides.length])
	const goToSlide = (index: number) => {
		setActiveIndex(index)
	}

	/* Local Hooks */
	const handlers = useBannerSwipeable(nextSlide, previousSlide)

	/* Autoplay Effect */
	useEffect(() => {
		let autoPlayInterval: NodeJS.Timeout

		const observer = new IntersectionObserver((entries) => {
			// If the component is in view, start the interval
			if (entries[0].isIntersecting) {
				autoPlayInterval = setInterval(nextSlide, interval * 1000)
			} else {
				// If the component is not in view, clear the interval
				clearInterval(autoPlayInterval)
			}
		})

		if (bannerRef.current) {
			observer.observe(bannerRef.current)
		}

		return () => {
			observer.disconnect()
			clearInterval(autoPlayInterval)
		}
	}, [activeIndex, interval, nextSlide])

	/* Local Components */
	const renderDesktopMainBannerItem = ({
		item,
	}: {
		item: HeroBannerSlideMainItem
	}) => {
		return (
			<div className={`${HeroBannerStyles['desktop-item']}`}>
				<h3
					className={`${generateCSSClassString(
						['desktop-item__text'],
						HeroBannerStyles
					)}`}
					style={{
						color: item.titleColor || '#fff',
						textAlign: item.titleAlignment || 'center',
					}}
				>
					{replaceEscapedNewlines(item.title)}
				</h3>
				<div style={{ alignSelf: item.ctaAlignment }}>
					<MainPrimaryLink
						text={item.ctaText}
						seoText={item.title}
						href={item.ctaLinkHref}
						color={item.ctaTextColor}
						backgroundColor={item.ctaBackgroundColor}
						borderColor={item.ctaBorderColor}
					/>
				</div>
			</div>
		)
	}

	const renderMobileContents = () => {
		const mainItem = slides[activeIndex].mainItem
		if (mainItem) {
			return (
				<div className={`${HeroBannerStyles['mobile-item-container']}`}>
					<div className={`${HeroBannerStyles['mobile-item']}`}>
						<h3 className={`${HeroBannerStyles['mobile-item-main']}`}>
							{replaceEscapedNewlines(mainItem.title)}
						</h3>
						<MainPrimaryLink
							text={mainItem.ctaText}
							seoText={mainItem.title}
							href={mainItem.ctaLinkHref}
							color={mainItem.ctaTextColor}
							backgroundColor={mainItem.ctaBackgroundColor}
							borderColor={mainItem.ctaBorderColor}
						/>
					</div>
				</div>
			)
		}

		const internalItem = slides[activeIndex].internalItem
		if (internalItem && internalItem.actionCTAs.length > 0) {
			return (
				<div className={`${HeroBannerStyles['mobile-item-container']}`}>
					<div className={`${HeroBannerStyles['mobile-item']}`}>
						{internalItem.actionCTAs.map((linkCta) => renderLinkCTA(linkCta))}
					</div>
				</div>
			)
		}
	}

	const MainPrimaryLink = ({
		text,
		seoText,
		href,
		backgroundColor = '#000',
		color = '#fff',
		borderColor = '#000',
	}: {
		text: string
		seoText: string
		href: string
		backgroundColor?: string
		color?: string
		borderColor?: string
	}) => {
		return (
			<SwitchLink
				className={`${HeroBannerStyles['link']}`}
				style={{ backgroundColor, color, borderColor, borderWidth: '1px' }}
				href={href}
			>
				{text}
				{seoText && (
					<span className={`${HeroBannerStyles['seo']}`}>
						{' '}
						- {replaceEscapedNewlines(seoText)}
					</span>
				)}
			</SwitchLink>
		)
	}

	const renderLinkCTA = (linkCta: HeroBannerSlideLinkCTA) => {
		return (
			<SwitchLink
				key={linkCta.text}
				href={linkCta.href}
				className={`${generateCSSClassString(
					[
						'cta-button',
						linkCta.type === 'secondary'
							? 'cta-button--secondary'
							: 'cta-button--primary',
					],
					HeroBannerStyles
				)}`}
			>
				{linkCta.text}
			</SwitchLink>
		)
	}

	const renderBannerContents = () => {
		const mainItem = slides[activeIndex].mainItem
		if (mainItem) {
			return (
				<div
					className={`${generateCSSClassString(
						['desktop-main-item-container'],
						HeroBannerStyles
					)}`}
					style={{
						left: mainItem.position.left,
						right: mainItem.position.right,
						bottom: mainItem.position.bottom,
						top: mainItem.position.top,
					}}
				>
					{renderDesktopMainBannerItem({ item: mainItem })}
				</div>
			)
		}
		return null
	}

	const renderInternalTitle = () => {
		const internalItem = slides[activeIndex].internalItem
		if (internalItem) {
			return (
				<div className={`${HeroBannerStyles['internal-item']}`}>
					<h3
						className={`${generateCSSClassString(
							['internal-item__title'],
							HeroBannerStyles
						)}`}
						style={{ color: internalItem.titleColor || '#fff' }}
					>
						{replaceEscapedNewlines(internalItem.title)}
					</h3>
					{internalItem.actionCTAs.length > 0 && (
						<div className={`${HeroBannerStyles['internal-item__ctas']}`}>
							{internalItem.actionCTAs.map((linkCta) => renderLinkCTA(linkCta))}
						</div>
					)}
				</div>
			)
		}

		return null
	}

	return (
		<div ref={bannerRef}>
			<div className={`${HeroBannerStyles['container']}`}>
				<div {...handlers} className={`${HeroBannerStyles['image-container']}`}>
					{slides.map((slide, index) => (
						<div
							key={slide.id}
							className={`${generateCSSClassString(
								['image-item', index === activeIndex ? 'image-item--show' : ''],
								HeroBannerStyles
							)}`}
						>
							<BannerPicture
								isLCP={index == 0 ? true : false}
								pictureDesktop={slide.picture.pictureDesktop}
								pictureMobile={slide.picture.pictureMobile}
								alt={slide.picture.alt}
							/>
						</div>
					))}
				</div>
				<div className={`${HeroBannerStyles['slider-container']}`}>
					{renderInternalTitle()}
					{slides.length > 1 && (
						<BannerSlider
							activeSliderIndex={activeIndex}
							slideCount={slides.length}
							goToSlide={goToSlide}
							activeIndexColor={slides[activeIndex].sliderTextColor}
							activeIndexSelectedColor={slides[activeIndex].sliderSelectedColor}
						/>
					)}
				</div>
				{renderBannerContents()}
			</div>
			{renderMobileContents()}
		</div>
	)
}

export default HeroBanner
