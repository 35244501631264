import React from 'react'

/* Helpers */
import {
	getImgixURLAtWidth,
	getImgixURLAtHeight,
	getImgixURL,
} from '@helpers/imgixHelper'

/* Local Types */
export interface BannerPicturePicture {
	width: number
	height: number
	src: string
	// quality: number
}
type SizesType = { bp: number; dim: number; layout?: string }[]
export interface BannerPictureProps {
	pictureDesktop: BannerPicturePicture
	pictureMobile: BannerPicturePicture
	alt: string
	isLCP?: boolean
	sizeOverrides?: SizesType
	isHeightBased?: boolean
}

/* Local Helpers */
const generateDPRSet = (url: string): string => {
	const urlSearchParams = new URLSearchParams(url)
	const width = urlSearchParams.get('w')
	const height = urlSearchParams.get('h')

	// Imgix DPR info: https://docs.imgix.com/apis/rendering/device-pixel-ratio
	if (width || height) {
		return [1, 2, 3]
			.map((density) => `${url}&dpr=${density} ${density}x`)
			.join(', ')
	}

	// Default to original URL (1x DPR)
	return url
}

/* Styles */
import BannerPictureStyles from './BannerPicture.module.scss'

const BannerPicture = ({
	pictureDesktop,
	pictureMobile,
	alt,
	isLCP,
	sizeOverrides,
	isHeightBased = false,
}: BannerPictureProps): React.ReactElement => {
	/* Fallback Urls */
	const desktopSrc: string = getImgixURL(pictureDesktop.src)
	const mobileSrc: string = getImgixURL(pictureMobile.src)

	/* Default assumes width based image that spans full screen */
	const defaultSizes: SizesType = [
		{
			bp: 320,
			dim: 320,
		},
		{
			bp: 416,
			dim: 416,
		},
		{
			bp: 640,
			dim: 640,
		},
		{
			bp: 768,
			dim: 768,
		},
		{
			bp: 1024,
			dim: 1024,
		},
		{
			bp: 1500,
			dim: 1500,
			layout: 'desktop',
		},
	]

	const sizes = sizeOverrides ?? defaultSizes

	/* Preload Props for LCP or Lazy */
	const preLoadProps: { [key: string]: string } = isLCP
		? { loading: 'eager', fetchpriority: 'high' }
		: { loading: 'lazy' }

	return (
		<picture>
			{sizes.map((size) => {
				const url =
					size.layout === 'desktop' ? pictureDesktop.src : pictureMobile.src
				const src: string = generateDPRSet(
					isHeightBased
						? getImgixURLAtHeight(url, Math.ceil(size.dim))
						: getImgixURLAtWidth(url, Math.ceil(size.dim))
				)

				return (
					<source
						key={size.bp}
						media={`(max-width: ${size.bp}px)`}
						srcSet={src}
					/>
				)
			})}
			<source srcSet={desktopSrc} />
			<img
				src={mobileSrc}
				alt={alt}
				width={1280}
				height={664}
				{...preLoadProps}
				className={BannerPictureStyles['img']}
			/>
		</picture>
	)
}

export default BannerPicture
