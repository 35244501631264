import React from 'react'

/* Helpers */
import { replaceEscapedNewlines } from '@lib/strapi/helpers'

/* Components */
import BannerPicture, { BannerPictureProps } from '../common/BannerPicture'

/* Styles */
import ImageBannerStyles from './ImageBanner.module.scss'

/* Local Types */
interface ImageBannerInfo {
	title?: string
	picture: BannerPictureProps
	// Color Stuff
	titleColor?: string
}

const ImageBanner = ({ info }: { info: ImageBannerInfo }) => {
	/* Local Components */
	const renderDesktopItems = () => {
		if (!info.title) {
			return null
		}
		return (
			<div className={`${ImageBannerStyles['desktop-items']}`}>
				<div className={`${ImageBannerStyles['desktop-items__cta-container']}`}>
					<h3
						style={{ color: info.titleColor || '#fff' }}
						className={`${ImageBannerStyles['desktop-items__cta-title']}`}
					>
						{replaceEscapedNewlines(info.title)}
					</h3>
				</div>
			</div>
		)
	}

	const renderMobileItems = () => {
		if (!info.title) {
			return null
		}
		return (
			<div className={`${ImageBannerStyles['mobile-items']}`}>
				<div className={`${ImageBannerStyles['mobile-items__cta-container']}`}>
					<div className={`${ImageBannerStyles['mobile-items__cta-flex']}`}>
						<h3
							style={{ color: info.titleColor || '#fff' }}
							className={`${ImageBannerStyles['mobile-items__cta-title']}`}
						>
							{replaceEscapedNewlines(info.title)}
						</h3>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={`${ImageBannerStyles['container']}`}>
			<div className={`${ImageBannerStyles['image-container']}`}>
				<BannerPicture
					pictureDesktop={info.picture.pictureDesktop}
					pictureMobile={info.picture.pictureMobile}
					alt={info.picture.alt}
				/>
			</div>
			{renderDesktopItems()}
			{renderMobileItems()}
		</div>
	)
}

export default ImageBanner
