import React from 'react'

/* Components */
import PageLoading from '@components/page/PageLoading'

/* Local Types */
interface LoadingProps {
	variant?: 'escape' | 'loading'
}

const Loading = ({ variant }: LoadingProps): React.ReactElement => {
	return <PageLoading maxWait={variant === 'escape' ? 0 : 2000} />
}

export default Loading
